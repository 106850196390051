<template>
  <div>
    <nav
      class="flex w-full mb-6 px-5 py-3 text-gray-700 border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-100"
      aria-label="Breadcrumb"
    >
      <ol
        class="inline-flex items-center justify-center space-x-1 md:space-x-3 m-0"
      >
        <li class="inline-flex items-center justify-center">
          <a
            href="/"
            class="inline-flex items-center justify-center text-sm font-medium text-gray-900"
          >
            <div class="flex justify-center">
              <div>
                <svg
                  class="w-4 h-4 mr-2.5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z"
                  />
                </svg>
              </div>

              <div>Inicio</div>
            </div>
          </a>
        </li>
        <li class="flex items-center justify-center">
          <div>
            <svg
              class="w-3 h-3 text-gray-400 mx-1"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 6 10"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m1 9 4-4-4-4"
              />
            </svg>
          </div>
        </li>
        <li class="flex items-center justify-center">
          <a
            href="/quotes"
            class="inline-flex items-center justify-center text-sm font-medium text-gray-900"
          >
            Carrito
          </a>
        </li>
      </ol>
    </nav>

    <div class="uk-flex uk-flex-between uk-flex-middle">
      <div>
        <button
          v-if="
            !isLoading &&
            userQuotables.filter(
              (userQuotable) => userQuotable.saved_for_later === 0
            ).length !== 0 &&
            storefrontStorefront.type === 'b2b'
          "
          :disabled="isLoading"
          @click="generateQuote"
          class="uk-button uk-button-primary uk-border-rounded"
        >
          <span v-if="isLoading" uk-spinner></span>
          <span v-else>Generar cotización</span>
        </button>
      </div>

      <router-link
        test="go-to-checkout-button"
        v-if="
          !isLoading &&
          userQuotables.filter(
            (userQuotable) => userQuotable.saved_for_later === 0
          ).length !== 0 &&
          storefrontStorefront.type === 'redeem'
        "
        tag="button"
        :disabled="false"
        to="/checkout"
        class="uk-button uk-button-primary uk-border-rounded"
      >
        Ir a checkout
      </router-link>
    </div>

    <UserQuotables
      :userQuotables="userQuotables"
      :isLoading="isLoading"
      :editable="true"
    />

    <div
      class="uk-text-center uk-margin-top"
      v-if="
        !isLoading &&
        userQuotables.filter(
          (userQuotable) => userQuotable.saved_for_later === 0
        ).length !== 0
      "
    >
      <button
        v-if="storefrontStorefront.type === 'b2b'"
        :disabled="isLoading"
        @click="generateQuote"
        class="uk-button uk-button-primary uk-border-rounded"
      >
        <span v-if="isLoading" uk-spinner></span>
        <span v-else>Generar cotización</span>
      </button>
      <router-link
        test="go-to-checkout-button"
        v-if="storefrontStorefront.type === 'redeem'"
        to="/checkout"
        tag="button"
        :disabled="false"
        class="uk-button uk-button-primary uk-border-rounded"
      >
        Ir a checkout
      </router-link>
    </div>

    <div v-if="storefrontStorefront.type === 'b2b'">
      <div class="uk-text-large uk-margin-top">Cotizaciones realizadas</div>
      <div v-if="!isLoading">
        <table
          class="uk-table uk-table-divider uk-table-hover uk-table-middle uk-visible@s"
          v-if="pastQuotes.length !== 0"
        >
          <thead>
            <th width="10%">#</th>
            <th width="20%">Fecha de cotización</th>
            <th class="uk-text-center" width="20%">Código de cotización</th>
            <th class="uk-text-center" width="10%">Cantidad de productos</th>
            <th width="10%" class="uk-text-center">Compartir por correo</th>
            <th width="10%" class="uk-text-center">Descargar</th>
            <th width="20%" class="uk-text-center">Aceptar cotizazión</th>
          </thead>
          <tbody>
            <tr
              v-for="pastQuote in pastQuotes"
              :key="pastQuote.id"
              :style="{
                backgroundColor: isRecent(pastQuote.created_at) ? '#ffd' : '',
              }"
            >
              <td class="uk-text-center uk-text-bold uk-text-large">
                #{{ pastQuote.user_sequence }}
              </td>
              <td>
                <div>{{ formatDatetime(pastQuote.created_at) }}</div>
                <div>(Expira el {{ formatDate(pastQuote.expires_at) }})</div>
              </td>
              <td class="uk-text-center">{{ pastQuote.code }}</td>
              <td class="uk-text-center">
                {{ pastQuote.quotables.length }}
              </td>
              <td class="uk-text-center">
                <div
                  v-if="hasntExpired(pastQuote.expires_at) && pastQuote.file"
                >
                  <button
                    class="uk-button uk-button-primary uk-border-rounded"
                    uk-icon="mail"
                  ></button>
                  <div uk-drop="mode: click">
                    <div
                      class="uk-card uk-padding-small uk-card-secondary uk-border-rounded"
                    >
                      <ValidationObserver v-slot="{ invalid }">
                        <ValidationProvider
                          v-for="(email, index) in shareToEmails"
                          :key="'email-' + index"
                          name="Correo destinatario"
                          rules="required|email"
                          v-slot="{ errors }"
                        >
                          <div class="uk-margin-top uk-text-left">
                            <span
                              v-if="shareToEmails.length !== 1"
                              uk-icon="icon: minus-circle; ratio: 1"
                              class="cursor-pointer"
                              @click="removeShareEmail(index)"
                            ></span>
                            Correo destinatario
                          </div>
                          <input
                            class="uk-input uk-border-rounded"
                            type="text"
                            name="Correo destinatario"
                            v-model="shareToEmails[index]"
                          />
                          <span>{{ errors[0] }}</span>
                        </ValidationProvider>

                        <div class="uk-margin-small-top">
                          <button
                            class="uk-button uk-button-primary uk-border-rounded"
                            @click="addShareEmail"
                          >
                            Agregar correo
                          </button>
                        </div>

                        <div class="uk-margin-small-top">
                          <button
                            class="uk-button uk-button-primary uk-border-rounded"
                            :disabled="invalid || isLoading"
                            @click="shareQuote(pastQuote)"
                          >
                            <span v-if="isLoading" uk-spinner></span>
                            <span v-else> Enviar </span>
                          </button>
                        </div>
                      </ValidationObserver>
                    </div>
                  </div>
                </div>
              </td>
              <td class="uk-text-center">
                <a
                  v-if="pastQuote.file"
                  :href="pastQuote.file.path"
                  target="_blank"
                  class="uk-button uk-button-primary uk-border-rounded"
                >
                  <span uk-icon="cloud-download"></span>
                </a>
              </td>
              <td class="uk-text-center">
                <router-link
                  v-if="pastQuote.valid"
                  :to="'/quotes/resolution/' + pastQuote.id"
                  class="uk-button uk-button-primary uk-border-rounded"
                  uk-icon="play"
                ></router-link>
                <div v-else>Expirada</div>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-else class="uk-text-center">
          No se han realizado cotizaciones
        </div>
      </div>
      <div v-else class="uk-text-center">
        <div uk-spinner></div>
      </div>
    </div>

    <div v-if="storefrontStorefront.type === 'redeem'">
      <div class="mt-8">
        <hr />
      </div>
      <div class="uk-text-large uk-margin-top">Compras realizadas</div>
      <div v-if="!isLoading">
        <table
          class="uk-table uk-table-divider uk-table-hover uk-table-middle uk-visible@s"
          v-if="pastRedeems.length !== 0"
        >
          <thead>
            <th width="30%">Fecha de compra</th>
            <th class="uk-text-center" width="10%">Código</th>
            <th class="uk-text-center" width="20%">Cantidad de productos</th>
            <th class="uk-text-center" width="20%">Total</th>
            <th width="20%"></th>
          </thead>
          <tbody>
            <tr v-for="pastRedeem in pastRedeems" :key="pastRedeem.id">
              <td>
                {{ formatDatetime(pastRedeem.created_at) }}
              </td>
              <td class="uk-text-center">
                {{ pastRedeem.code }}
              </td>
              <td class="uk-text-center">
                {{
                  pastRedeem.redeem_items.reduce(
                    (accum, item) => accum + item.quantity,
                    0
                  )
                }}
              </td>
              <td
                v-if="pastRedeem.is_rollback"
                class="flex items-center justify-center"
              >
                <div
                  class="bg-red-500 text-white rounded-full px-4 py-2 w-32 text-center"
                >
                  Cancelada
                </div>
              </td>
              <td v-else class="uk-text-center">
                {{
                  formatThousands(
                    pastRedeem.redeem_items.reduce(
                      (accum, item) => accum + item.quantity * item.point_price,
                      0
                    )
                  )
                }}
                {{ pointsName }}
              </td>
              <td>
                <router-link
                  :to="'/redeem/' + pastRedeem.id"
                  tag="button"
                  :disabled="false"
                  class="uk-button uk-button-primary uk-border-rounded"
                >
                  Ver compra
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import UIkit from "uikit";
import { mapGetters } from "vuex";
import UserQuotables from "@/views/Utils/UserQuotables";

export default {
  name: "QuotesIndex",

  components: {
    UserQuotables,
  },

  data() {
    return {
      model: {
        quantity: null,
        faces: null,
        colors: null,
      },
      shareToEmails: [""],
      selectedUserQuotable: null,
      mode: "edit",
      isLoading: true,
      userQuotables: [],
      pastQuotes: [],
      pastRedeems: [],
    };
  },

  computed: {
    ...mapGetters(["storefrontStorefront", "pointsName"]),
  },

  mounted() {
    this.getPayload();
  },

  methods: {
    isRecent(timestamp) {
      return moment().diff(moment(timestamp), "minutes") < 30;
    },
    getPayload() {
      this.isLoading = true;
      this.axios.get("/quotes").then(({ data: { userQuotables } }) => {
        this.userQuotables = userQuotables;
      });
      if (this.storefrontStorefront.type === "b2b") {
        this.axios
          .get("/quotes/past-quotes")
          .then(({ data: { pastQuotes } }) => {
            this.pastQuotes = pastQuotes;
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
      if (this.storefrontStorefront.type === "redeem") {
        this.axios
          .get("/quotes/past-redeems")
          .then(({ data: { pastRedeems } }) => {
            this.pastRedeems = pastRedeems;
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    hasntExpired(date) {
      return moment.utc(date).isAfter(moment());
    },
    addShareEmail() {
      this.shareToEmails.push("");
    },
    removeShareEmail(index) {
      this.shareToEmails.splice(index, 1);
    },
    shareQuote(quote) {
      this.isLoading = true;
      this.axios
        .post("/quotes/" + quote.id + "/share", {
          emails: this.shareToEmails,
        })
        .then(() => {
          this.shareToEmails = [""];
          UIkit.notification({
            message: "Se ha enviado la cotización",
            status: "success",
            pos: "bottom-left",
          });
        })
        .catch((error) => {
          console.log("error", error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    generateQuote() {
      this.isLoading = true;
      this.axios
        .get("/quotes/generate")
        .then(({ data: { pastQuotes } }) => {
          this.userQuotables = [];
          this.pastQuotes = pastQuotes;

          window.open(this.pastQuotes[0].file.path, "_blank").focus();
        })
        .catch((error) => {
          console.log("error", error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
